/* =================================== */
/*  6. Footer
/* =================================== */

#footer {
  background: #fff;
  color: #252b33;
  padding: 66px 0px;
  padding: 4.125rem 0;

  .nav {
    .nav-item {
      display: inline-block;
      line-height: 12px;
      margin: 0;

      .nav-link {
        color: #252b33;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;

        &:focus {
          color: $primary-color;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
      }
    }

    .nav-link:hover {
      color: $primary-color;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
  }

  .footer-copyright {
    border-top: 1px solid #e2e8ea;
    padding: 0px 0px;
    color: #67727c;

    .nav {
      .nav-item .nav-link {
        color: #67727c;
      }

      .nav-link:hover {
        color: $primary-color;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
    }
  }

  .nav.flex-column .nav-item {
    padding: 0px;

    .nav-link {
      margin: 0.7rem 0px;
    }
  }

  &.footer-text-light {
    color: rgba(250, 250, 250, 0.8);

    .nav .nav-item .nav-link {
      color: rgba(250, 250, 250, 0.8);

      &:hover {
        color: rgba(250, 250, 250, 1);
      }
    }

    .footer-copyright {
      border-color: rgba(250, 250, 250, 0.15);
      color: rgba(250, 250, 250, 0.5);
    }

    &:not(.bg-primary) .social-icons-light.social-icons li a {
      color: rgba(250, 250, 250, 0.8);

      &:hover {
        color: rgba(250, 250, 250, 1);
      }
    }

    &.bg-primary {
      color: #fff;

      .nav .nav-item .nav-link {
        color: #fff;

        &:hover {
          color: rgba(250, 250, 250, 0.7);
        }
      }

      .footer-copyright {
        border-color: rgba(250, 250, 250, 0.15);
        color: rgba(250, 250, 250, 0.9);
      }

      :not(.social-icons) a {
        color: #fff;

        &:hover {
          color: rgba(250, 250, 250, 0.7);
        }
      }
    }
  }
}

html:not([dir="rtl"]) #footer {
  .nav {
    .nav-item {
      &:first-child .nav-link {
        padding-left: 0px;
      }

      &:last-child .nav-link {
        padding-right: 0px;
      }
    }
  }
}

html[dir="rtl"] #footer {
  .nav {
    .nav-item {
      &:first-child .nav-link {
        padding-right: 0px;
      }

      &:last-child .nav-link {
        padding-left: 0px;
      }
    }
  }
}

/*=== 6.1 Social Icons ===*/
.social-icons {
  margin: 0;
  padding: 0;
  padding-left: 7px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;

  li {
    margin: 2px 6px;
    padding: 0;
    overflow: visible;

    a {
      display: block;
      height: 26px;
      line-height: 26px;
      width: 26px;
      font-size: 20px;
      text-align: center;
      color: #4d555a;
      text-decoration: none;
      @include transition(all 0.2s ease);
    }

    i {
      line-height: inherit;
    }
  }

  &.social-icons-sm li {
    margin: 2px 4px;
  }

  &.social-icons-sm li a {
    font-size: 16px;
  }

  &.social-icons-lg li a {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 26px;
  }

  &.social-icons-light li a {
    color: #eee;
  }

  &.social-icons-muted li a {
    color: #aab1b8;
  }

  li:hover {
    a {
      color: #999;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  li:hover.social-icons-twitter a,
  &.social-icons-colored li.social-icons-twitter a {
    color: $twitter;
  }

  li:hover.social-icons-facebook a,
  &.social-icons-colored li.social-icons-facebook a {
    color: $facebook;
  }

  li:hover.social-icons-linkedin a,
  &.social-icons-colored li.social-icons-linkedin a {
    color: $linkedin;
  }

  li:hover.social-icons-rss a,
  &.social-icons-colored li.social-icons-rss a {
    color: $rss;
  }

  li:hover.social-icons-dribbble a,
  &.social-icons-colored li.social-icons-dribbble a {
    color: $dribbble;
  }

  li:hover.social-icons-github a,
  &.social-icons-colored li.social-icons-github a {
    color: $github;
  }

  li:hover.social-icons-behance a,
  &.social-icons-colored li.social-icons-behance a {
    color: $behance;
  }

  li:hover.social-icons-google a,
  &.social-icons-colored li.social-icons-google a {
    color: $google;
  }

  li:hover.social-icons-pinterest a,
  &.social-icons-colored li.social-icons-pinterest a {
    color: $pinterest;
  }

  li:hover.social-icons-youtube a,
  &.social-icons-colored li.social-icons-youtube a {
    color: $youtube;
  }

  li:hover.social-icons-instagram a,
  &.social-icons-colored li.social-icons-instagram a {
    color: $instagram;
  }

  li:hover.social-icons-skype a,
  &.social-icons-colored li.social-icons-skype a {
    color: $skype;
  }

  li:hover.social-icons-email a,
  &.social-icons-colored li.social-icons-email a {
    color: $email;
  }

  li:hover.social-icons-vk a,
  &.social-icons-colored li.social-icons-vk a {
    color: $vk;
  }

  li:hover.social-icons-xing a,
  &.social-icons-colored li.social-icons-xing a {
    color: $xing;
  }

  li:hover.social-icons-tumblr a,
  &.social-icons-colored li.social-icons-tumblr a {
    color: $tumblr;
  }

  li:hover.social-icons-reddit a,
  &.social-icons-colored li.social-icons-reddit a {
    color: $reddit;
  }

  li:hover.social-icons-delicious a,
  &.social-icons-colored li.social-icons-delicious a {
    color: $delicious;
  }

  li:hover.social-icons-stumbleupon a,
  &.social-icons-colored li.social-icons-stumbleupon a {
    color: $stumbleupon;
  }

  li:hover.social-icons-digg a,
  &.social-icons-colored li.social-icons-digg a {
    color: $digg;
  }

  li:hover.social-icons-blogger a,
  &.social-icons-colored li.social-icons-blogger a {
    color: $blogger;
  }

  li:hover.social-icons-flickr a,
  &.social-icons-colored li.social-icons-flickr a {
    color: $flickr;
  }

  li:hover.social-icons-vimeo a,
  &.social-icons-colored li.social-icons-vimeo a {
    color: $vimeo;
  }

  li:hover.social-icons-yahoo a,
  &.social-icons-colored li.social-icons-yahoo a {
    color: $yahoo;
  }

  li:hover.social-icons-apple a,
  &.social-icons-colored li.social-icons-apple a {
    color: $appstore;
  }

  &.social-icons-colored li {
    &:hover a {
      color: #999;
    }
  }
}

html:not([dir="rtl"]) .social-icons {
  li {
    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
}

html[dir="rtl"] .social-icons {
  li {
    &:first-child {
      margin-right: 0px;
    }

    &:last-child {
      margin-left: 0px;
    }
  }
}

/*=== 6.2 Back to Top ===*/
#back-to-top {
  display: none;
  position: fixed;
  z-index: 1030;
  bottom: 8px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #fff;
  font-size: 16px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
  @include transition(all 0.3s ease-in-out);
  @include box-shadow(0px 5px 15px rgba(0, 0, 0, 0.15));

  &:hover {
    background-color: $primary-color;
    color: #000;
    @include box-shadow(0px 5px 15px rgba(0, 0, 0, 0.25));
    @include transition(all 0.3s ease-in-out);
  }
}

html[dir="rtl"] body:not(.side-header-right) #back-to-top,
html:not([dir="rtl"]).side-header-right #back-to-top {
  left: 10px;
  right: auto;
}

@include media-breakpoint-down(xs) {
  #back-to-top {
    z-index: 1029;
  }
}